import {Flex, Text} from '@indoqa/style-system'
import React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ActiveSession} from '../../state/user-management.types'
import {DetailsHeader} from '../commons/DetailsHeader'
import {DetailsSection} from '../commons/DetailsSection'
import {ActiveSessionDetailsField} from './ActiveSessionDetailsField'

interface Props {
  session: ActiveSession
}

interface JWTParts {
  header: string
  payload: string
  signature: string
}

/**
 * Decodes a base64 URL-encoded string.
 * @param input - The base64 URL-encoded string.
 * @returns The decoded string.
 */
function base64UrlDecode(input: string): string {
  let base64 = input.replace(/-/g, '+').replace(/_/g, '/')
  while (base64.length % 4 !== 0) {
    base64 += '='
  }
  return atob(base64)
}

/**
 * Parses a JWT token and returns an object with its three parts as formatted JSON strings.
 * @param jwtToken - The JWT string.
 * @returns An object containing the header, payload, and signature as JSON strings.
 */
function parseJWT(jwtToken: string): JWTParts {
  if (!jwtToken) {
    return {
      payload: '',
      header: '',
      signature: '',
    }
  }
  const parts = jwtToken.split('.')
  if (parts.length !== 3) {
    throw new Error('Invalid JWT format.')
  }

  const [headerPart, payloadPart, signaturePart] = parts

  // Parse and format header and payload as JSON strings.
  const headerObject = JSON.parse(base64UrlDecode(headerPart))
  const payloadObject = JSON.parse(base64UrlDecode(payloadPart))

  const formattedHeader = JSON.stringify(headerObject, null, 2)
  const formattedPayload = JSON.stringify(payloadObject, null, 2)

  // For consistency, wrap the signature in a JSON string.
  const formattedSignature = JSON.stringify(signaturePart, null, 2)

  return {
    header: formattedHeader,
    payload: formattedPayload,
    signature: formattedSignature,
  }
}

export function ActiveSessionDetailsPanel({session}: Props) {
  const {css} = useFela<Theme>()
  const accessTokenJwtParts = parseJWT(session.accessToken)
  const refreshTokenJwtParts = parseJWT(session.refreshToken)

  return (
    <Flex direction="column" nowrap>
      <DetailsHeader>{session.id}</DetailsHeader>

      <DetailsSection name="Benutzername">
        <ActiveSessionDetailsField value={session.username} />
      </DetailsSection>

      <DetailsSection name="Erstellungsdatum">
        <ActiveSessionDetailsField value={session.issuedAt} />
      </DetailsSection>

      <DetailsSection name="Ablaufdatum">
        <ActiveSessionDetailsField value={session.expiration} />
      </DetailsSection>

      <DetailsSection name="State">
        <ActiveSessionDetailsField value={session.state} />
      </DetailsSection>

      <DetailsSection name="Permissions">
        <ul>
          {session.permissions.map((permission) => (
            <li key={permission}>
              <Text fontSize="small">{permission}</Text>
            </li>
          ))}
        </ul>
      </DetailsSection>

      <DetailsSection name="Access-Token">
        <pre className={css({fontSize: 'small'})}>{accessTokenJwtParts.payload}</pre>
      </DetailsSection>

      <DetailsSection name="Refresh-Token">
        <pre className={css({fontSize: 'small'})}>{refreshTokenJwtParts.payload}</pre>
      </DetailsSection>
    </Flex>
  )
}
