import {useDebouncedUserInput, useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import {ajax} from 'rxjs/ajax'
import {SearchInput} from '../../../common/components/search-input/SearchInput'
import {AutoCompleteItem, AutoCompleteItemTargetType} from '../../../common/components/search-input/SearchInput.types'
import {ReactComponent as PersonIcon} from '../../../common/icons/images/person.svg'
import {loadActiveSessions$} from '../../state/user-management.services'
import {ActiveSession} from '../../state/user-management.types'
import {BV_REQUEST_PARAMS, userManagementActiveSessionsPath} from '../../user-management.paths'
import {SEARCH_INPUT_STYLE} from '../commons/SearchInput.style'
import {ActiveSessionListItem} from './ActiveSesionsListItem'

interface Props {
  sessionId: string | null | undefined
}
interface ActiveSessionAutoCompleteItem extends AutoCompleteItem {
  activeSession: ActiveSession
}

const convertActiveSessionToAutoSuggest = (activeSession: ActiveSession): ActiveSessionAutoCompleteItem => {
  return {
    text: activeSession.username,
    proposal: '',
    targetType: AutoCompleteItemTargetType.SUGGESTION,
    activeSession,
  }
}

export function ActiveSessionsListPanel({sessionId}: Props) {
  const filter = useQueryParam(BV_REQUEST_PARAMS.filter)
  const history = useHistory()
  const [userInput, setUserInput] = React.useState<string>(filter || '')
  const [activeSessions, setActiveSessions] = React.useState<ActiveSessionAutoCompleteItem[]>([])
  const setDebouncedUserInput = useDebouncedUserInput(setUserInput)

  React.useEffect(() => {
    history.push(userManagementActiveSessionsPath(sessionId))
  }, [history, sessionId])

  React.useEffect(() => {
    loadActiveSessions$(ajax).subscribe((activeSessions) => {
      const nextActiveSessionsItems = activeSessions
        .filter((session) => session.username && session.username.indexOf(userInput) >= 0)
        .map(convertActiveSessionToAutoSuggest)
      setActiveSessions(nextActiveSessionsItems)
    })
  }, [userInput])

  const onSelect = (activeSession: ActiveSession) => {
    history.push(userManagementActiveSessionsPath(activeSession.id))
  }

  return (
    <SearchInput
      inputValue={userInput}
      items={activeSessions}
      placeholder="Filter by Username"
      search={() => undefined}
      reload={(prefix: string) => setDebouncedUserInput(prefix)}
      isSelectedSuggestion={(suggestion) => suggestion.activeSession.id === sessionId}
      selectSuggestion={(suggestion) => onSelect(suggestion.activeSession)}
      renderItem={(item) => <ActiveSessionListItem activeSession={item.activeSession} filter={userInput} />}
      renderItemIcon={() => <PersonIcon />}
      styleConfig={{borderRadius: 0}}
      styles={SEARCH_INPUT_STYLE}
      alwaysShowMenu
    />
  )
}
