import {IndoqaApplication} from '@indoqa/react-app'
import {createFelaConfig} from '@indoqa/style-system'
import {createRenderer} from 'fela'
import * as ReactDOM from 'react-dom'
import App from './app/App'
import {authenticator} from './app/auth/SessionPlusAuthenticator'
import createStore from './app/createStore'
import history from './app/history'
import theme from './app/theme'

const rootEl = document.getElementById('app')

// support silent SSO login
authenticator.init(() => {
  const store = createStore()
  const felaConfig = createFelaConfig(theme.breakpoints, undefined, '_')
  const renderer = createRenderer(felaConfig)

  ReactDOM.render(
    <IndoqaApplication history={history} store={store} renderer={renderer}>
      <App />
    </IndoqaApplication>,
    rootEl
  )

  if (module.hot) {
    module.hot.accept('./app/App', () => {
      const NextApp = require('./app/App.tsx').default
      ReactDOM.render(
        <IndoqaApplication history={history} store={store} renderer={renderer}>
          <NextApp />
        </IndoqaApplication>,
        rootEl
      )
    })
  }
})
