import * as React from 'react'
import {authenticator} from '../../../app/auth/SessionPlusAuthenticator'

export const ProtectedComponent: React.FC = ({children}) => {
  if (!authenticator.isAuthenticated()) {
    return <div>Keine Berechtigung.</div>
  }

  return <>{children}</>
}
